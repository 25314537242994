const Header = () => {
    return (
        <div className="bg-finapp-blue">
            <div className="container mx-auto">
                <div className="ml-2">
                    <img className="h-12 py-2" alt="Finapply Logo Header" src="/imgs/finapp-f-white-transparent.png"/> 
                </div>
            </div>
        </div>
    )
}

export default Header