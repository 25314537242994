const HomePage = () => {
    return (
        <div>
            <div className="home-banner">
                <div className="flex w-full h-full justify-center items-center px-4">
                    <div>
                        <div className="mb-20">
                            <img className="h-72" alt="Finapp Logo" src="/imgs/finapp-f-blue-transparent.png" />
                        </div>
                        <div className="text-finapp-blue text-7xl text-center mb-5">Finapply</div>
                    </div>
                </div>
            </div>
            <div className="bg-finapp-blue text-white">
                <div className="container mx-auto">
                    <div className="h-screen flex justify-center items-center px-4">
                        <div>
                            <div className="text-center text-4xl mb-14">Finapply is a personal finance app.</div>
                            <div className="text-center text-2xl mb-14">It allows you to manage your personal finances and helps you to budget for the future.</div>
                            <div className="text-center text-xl mb-8">For any support please contact via email:</div>
                            <div className="text-center text-xl"><a href="mailto:finapplyapp@gmail.com"><span className="text-finapp-blue bg-white rounded py-2 px-5">finapplyapp@gmail.com</span></a></div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="py-16 container mx-auto">
                    <div className="px-4">
                        <div className="text-3xl mb-14 text-center">Features include:</div>
                        <div className="grid xl:grid-cols-4 sm:grid-cols-2">
                            <div className="mb-10">
                                <div className="text-center mb-5 text-xl h-16">Setting a start amount</div>
                                <div className="flex justify-center items-center">
                                    <div className="p-7">
                                        <img alt="Finappply Screen Start" className="border border-white" src="/imgs/screen-start.png" />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-10">
                                <div className="text-center mb-5 text-xl h-16">Adding "ins", weekly, monthly or fixed</div>
                                <div className="flex justify-center items-center">
                                    <div className="p-7">
                                        <img alt="Finappply Screen Add In" className="border border-white" src="/imgs/screen-add-in.png" />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-10">
                                <div className="text-center mb-5 text-xl h-16">Adding "outs", weekly, monthly or fixed</div>
                                <div className="flex justify-center items-center">
                                    <div className="p-7">
                                        <img alt="Finappply Screen Add Out" className="border border-white" src="/imgs/screen-add-out.png" />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-10">
                                <div className="text-center mb-5 text-xl h-16">Seeing your menu page with all details</div>
                                <div className="flex justify-center items-center">
                                    <div className="p-7">
                                        <img alt="Finappply Screen Menu" className="border border-white" src="/imgs/screen-menu.png" />
                                    </div>
                                </div>
                            </div>
                            <div className="xl:block hidden"></div>
                            <div className="mb-10">
                                <div className="text-center mb-5 text-xl h-16">Seeing your summary page which includes a forecast graph</div>
                                <div className="flex justify-center items-center">
                                    <div className="p-7">
                                        <img alt="Finappply Screen Graph" className="border border-white" src="/imgs/screen-graph.png" />
                                    </div>
                                </div></div>
                            <div className="mb-10">
                                <div className="text-center mb-5 text-xl h-16">And a Summary page that includes your future transactions</div>
                                <div className="flex justify-center items-center">
                                    <div className="p-7">
                                        <img alt="Finappply Screen Transactions" className="border border-white" src="/imgs/screen-transactions.png" />
                                    </div>
                                </div>
                            </div>
                            <div className="xl:block hidden"></div>
                        </div >
                    </div >
                </div >
            </div >
        </div >
    )
}

export default HomePage;