import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import PrivacyPage from "./pages/PrivacyPage";

const App = () => {
  return (
    <div>
      <Header />
      <div>
        <BrowserRouter>
          <Routes>
          <Route path="/" element={<HomePage />}></Route>
          <Route path="/privacy" element={<PrivacyPage />}></Route>
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
      
    </div>
  );
}

export default App;
