import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className="flex justify-center py-3">
            <div>
                <div className="inline mr-4"><Link to="/privacy" className="text-finapp-blue underline">Privacy Policy</Link></div> 
                <div className="inline">&copy; Copyright 2022</div>
            </div>
        </div>
    )
}

export default Footer