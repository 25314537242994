const PrivacyPage = () => {
    return (
        <div>
            <div className="container mx-auto">
                <div className="my-16 mx-4">
                    <div className="font-bold text-3xl mb-12">Privacy Policy</div>
                    <ol>
                        <li className="mb-3">
                            <div className="">We respect your privacy</div>
                            <ol type="a" className="list-disc ml-6">
                                <li>Finapply respects your right to privacy and is committed to safeguarding the privacy of our app users. We adhere to the Australian Privacy Principles container in the Privacy Act 1998(Cth). This policy sets out how we collect and treat your personal information.</li>
                                <li>“Personal Information” is information we hold which is identifiable as being about you.</li>
                            </ol>
                        </li>
                        <li className="mb-3">
                            <div>Collection of personal information</div>
                            <ol type="a" className="list-disc ml-6">
                                <li>Finapply does not collect any of your personal information. All information regarding your personal finances is stored locally on the app.</li>
                            </ol>
                        </li>
                        <li className="mb-3">
                            <div>How we collect your personal information</div>
                            <ol type="a" className="list-disc ml-6">
                                <li>Finapply does not collect any of your personal information.</li>
                            </ol>
                        </li>
                        <li className="mb-3">
                            <div>Use of your personal information</div>
                            <ol type="a" className="list-disc ml-6">
                                <li>Because Finapply does not collect any of your personal information we do not use it.</li>
                            </ol>
                        </li>
                        <li className="mb-3">
                            <div>Disclosure of your personal information</div>
                            <ol type="a" className="list-disc ml-6">
                                <li>Finapply does not disclose any of your personal information.</li>
                            </ol>
                        </li>
                        <li className="mb-3">
                            <div>Security of your personal information</div>
                            <ol type="a" className="list-disc ml-6">
                                <li>Finapply stores your information such as start amount, ins, and outs all locally on the app. Your information is secured in the app.</li>
                            </ol>
                        </li>
                        <li className="mb-3">
                            <div>Access to your personal information</div>
                            <ol type="a" className="list-disc ml-6">
                                <li>You have access to all your personal information on the app.</li>
                                <li>Because we do not collect any of your personal information on the app we do not have access to it.</li>
                            </ol>
                        </li>
                        <li className="mb-3">
                            <div>Complaints about privacy</div>
                            <ol type="a" className="list-disc ml-6">
                                <li>If you have any complaints about our privacy practices, please feel free to send in details of your complaints to <a className="text-finapp-blue underline" href="mailto:finapplyapp@gmail.com">finapplyapp@gmail.com</a>. We take complaints very seriously and will respond shortly after receiving written notice of your complaint.</li>
                            </ol>
                        </li>
                        <li className="mb-3">
                            <div>Changes to Privacy Policy</div>
                            <ol type="a" className="list-disc ml-6">
                                <li>Please be aware that we may change this Privacy Policy in the future. We may modify this Policy at any time, in our sole discretion and all modifications will be effective immediately upon our posting of the modifications on our website or notice board. Please check back from time to time to review our Privacy Policy.</li>
                            </ol>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPage;